import IconCross from 'assets/images/_icons/iconCross.png';
import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import SEO from 'components/_layout/Seo';
import * as Common from 'components/_universal/Common';
import { useTheme } from 'layout/ThemeContext';
import { useEffect } from 'react';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { TagTile } from 'sections/Blog/BlogItem/BlogItem.styled';
import { CaseStudyItem } from 'sections/CaseStudies/CaseStudyItem';
import { CASE_STUDIES } from 'shared/data/CaseStudies.data';
import styled, { css } from 'styled-components';
import { B3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import React from 'react';

export const CaseStudiesGrid = styled(Common.Div)(
    () =>
        css`
            display: grid;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
            grid-template-columns: repeat(auto-fill, 350px);
            grid-template-rows: repeat(auto-fill, 497px);

            @media (max-width: 400px) {
                grid-template-columns: repeat(auto-fill, 100%);
                grid-template-rows: repeat(auto-fill, 497px);
            }
        `,
);

export default () => {
    const isMd = useBreakpoint('md');
    const { caseStudiesTagsSelected, setCaseStudiesTagsSelected } = useTheme();

    const handleTagRemove = (tagName: string) => {
        setCaseStudiesTagsSelected(
            caseStudiesTagsSelected.filter((b) => b !== tagName),
        );
    };

    useEffect(() => {
        return () => {
            setCaseStudiesTagsSelected([]);
        };
    }, []);

    const filterCaseStudiesByTags =
        caseStudiesTagsSelected.length > 0
            ? CASE_STUDIES.filter((c) =>
                  c.hashtags.some((e) => caseStudiesTagsSelected.includes(e)),
              )
            : CASE_STUDIES;

    return (
        <CommonMargin>
            <SEO
                title={'Case studies'}
                meta={[
                    {
                        name: 'keywords',
                        content:
                            'large language models vs generative ai, embedded ai',
                    },
                ]}
                description={
                    'Discover the differences between large language models, generative AI, and embedded AI. Learn how these cutting-edge technologies shape natural language processing, personalized experiences, and real-time data applications.'
                }
            />
            <Section isFirst title="case studies" variant="secondary">
                <B3 mt={2} maxW={isMd ? '700px' : '100%'}>
                    Over the years, we’ve gained experience and know-how,
                    {isMd && <br />} thanks to which we are able to meet both
                    market challenges and clients’ needs. <br /> We deliver
                    innovative and high-quality solutions.
                </B3>
                {caseStudiesTagsSelected.length > 0 && (
                    <>
                        <Common.Div flex="row" gap="8px" flexWrap="wrap" mt={4}>
                            <B3>Tags: </B3>
                            {caseStudiesTagsSelected.map((b) => (
                                <TagTile
                                    key={b}
                                    onClick={() => handleTagRemove(b)}
                                >
                                    {`#${b}`}
                                    <Common.Svg
                                        src={IconCross}
                                        w="10px"
                                        h="10px"
                                    />
                                </TagTile>
                            ))}
                        </Common.Div>
                    </>
                )}
            </Section>
            <Section>
                <CaseStudiesGrid>
                    {filterCaseStudiesByTags.map(
                        ({ src: bgSrc, desc, link, title }) => (
                            <CaseStudyItem
                                bgSrc={bgSrc}
                                desc={desc}
                                link={link}
                                title={title}
                            />
                        ),
                    )}
                </CaseStudiesGrid>
            </Section>
            <TakeThisStepSection />
            <TrustedBySection />
        </CommonMargin>
    );
};
