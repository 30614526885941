import bgVoice from 'assets/images/CaseStudies/AIVOICE_case_studies.png';
import bgFishial from 'assets/images/CaseStudies/FISHIAL_case_studies.png';
import bgMOBD from 'assets/images/CaseStudies/MOBD_case_studies.png';
import bgNDA from 'assets/images/CaseStudies/NDA_case_studies.png';
import bgNUUS from 'assets/images/CaseStudies/NUUS_case_studies.png';
import bgRespect from 'assets/images/CaseStudies/RESPECT_ENERGY_case_studies.png';
import bgSpoton from 'assets/images/CaseStudies/SPOTON_case_studies.png';
import bgTickPredict from 'assets/images/CaseStudies/TICKPREDICT_case_studies.png';
import bgTulerie from 'assets/images/CaseStudies/TULERIE_case_studies.png';
import bgUdacity from 'assets/images/CaseStudies/UDACITY_case_studies.png';
import {
    PROJECT_FISHIAL,
    PROJECT_HOTELS,
    PROJECT_MOBD,
    PROJECT_NUUS,
    PROJECT_RESPECT,
    PROJECT_SPOTON,
    PROJECT_TICK,
    PROJECT_TULERIE,
    PROJECT_UDACITY,
    PROJECT_VOICE,
} from 'shared/data/Project.data';
import {
    ROUTE_CASE_STUDIES_FISHIAL,
    ROUTE_CASE_STUDIES_HOTELS,
    ROUTE_CASE_STUDIES_MOBD,
    ROUTE_CASE_STUDIES_NUUS,
    ROUTE_CASE_STUDIES_RESPECT,
    ROUTE_CASE_STUDIES_SPOTON,
    ROUTE_CASE_STUDIES_TICK_PREDICT,
    ROUTE_CASE_STUDIES_TULERIE,
    ROUTE_CASE_STUDIES_UDACITY,
    ROUTE_CASE_STUDIES_VOICE,
} from 'shared/paths';

export const CASE_STUDIES = [
    {
        projectName: PROJECT_SPOTON,
        title: 'Mobile payment technology and a management system',
        desc: 'From idea to execution. Client approached us with an idea for [...]',
        shortDesc: 'Mobile payment technology and a management system',
        link: ROUTE_CASE_STUDIES_SPOTON,
        industry: 'retail',
        hashtags: [
            'integrations with 3rd party',
            'payment gateways',
            'payments',
            'hardware',
            'admin panel',
        ],
        src: bgSpoton,
    },
    {
        projectName: PROJECT_FISHIAL,
        title: 'Fish recognition and classification platform',
        desc: 'Lack of globally available database with fish species. The idea was to build largest open-source fish [...]',
        shortDesc: 'Fish recognition and classification platform',
        link: ROUTE_CASE_STUDIES_FISHIAL,
        industry: 'community',
        hashtags: ['ai', 'vision processing', 'admin panels', 'software'],
        src: bgFishial,
    },
    {
        projectName: PROJECT_TICK,
        title: 'Trading platform for trading veterans',
        desc: 'Client wanted to enter crypto-trading market by providing possibility to trade from one place [...]',
        shortDesc: 'Trading platform for trading veterans',
        link: ROUTE_CASE_STUDIES_TICK_PREDICT,
        industry: 'financial',
        hashtags: ['integrations with 3rd party', 'software'],

        src: bgTickPredict,
    },
    {
        projectName: PROJECT_TULERIE,
        title: 'Peer-peer clothing rental community',
        desc: 'Tulerie is a peer-to-peer luxury rental app with shopping, sustainability, and sharing on the mind. [...]',
        shortDesc: 'Peer-peer clothing rental community',
        link: ROUTE_CASE_STUDIES_TULERIE,
        industry: 'entertainment',
        hashtags: ['software', 'fashion', 'entertainment'],

        src: bgTulerie,
    },
    {
        projectName: PROJECT_VOICE,
        title: 'Automated AI-based medical interview',
        desc: 'The scope of the project is to carry out interdisciplinary research, development [...]',
        shortDesc: 'Automated AI-based medical interview',
        link: ROUTE_CASE_STUDIES_VOICE,
        industry: 'healthcare',
        hashtags: ['software', 'hardware'],
        src: bgVoice,
    },
    {
        projectName: PROJECT_MOBD,
        title: 'Fleet management system',
        desc: 'Fractured market providers without complex solution that would bring the latest hardware devices along [...]',
        shortDesc: 'Fleet management system',
        link: ROUTE_CASE_STUDIES_MOBD,
        industry: 'automotive',
        hashtags: ['embedded-software', 'hardware'],

        src: bgMOBD,
    },
    {
        projectName: PROJECT_NUUS,
        title: 'Nuus - sports managing system',
        desc: 'Nuus is Saas product, browser-based administration panel for managing sports club multimedia content [...]',
        shortDesc: 'Nuus - sports managing system',
        link: ROUTE_CASE_STUDIES_NUUS,
        industry: 'sports',
        hashtags: ['software', 'hardware'],

        src: bgNUUS,
    },
    {
        projectName: PROJECT_RESPECT,
        title: 'Green energy hardware control devices',
        desc: 'Respect Energy brings together independent power producers, accredited and institutional [...]',
        shortDesc: 'Green energy hardware control devices',
        link: ROUTE_CASE_STUDIES_RESPECT,
        industry: 'energy',
        hashtags: ['software', 'hardware'],

        src: bgRespect,
    },
    {
        projectName: PROJECT_UDACITY,
        title: 'Enterprise schooling educational system',
        desc: 'Udacity is an American educational organization founded in 2011, offering massive open online courses [...]',
        shortDesc: 'Enterprise schooling educational system',
        link: ROUTE_CASE_STUDIES_UDACITY,
        industry: 'information technology',
        hashtags: ['software', 'information technology'],

        src: bgUdacity,
    },
    {
        projectName: PROJECT_HOTELS,
        title: 'Hotel reservation system',
        desc: 'NDA is one of the pioneers in a new category on the European market offering private purpose-built [...]',
        shortDesc: 'Hotel reservation system',
        link: ROUTE_CASE_STUDIES_HOTELS,
        industry: 'hospitality',
        hashtags: ['software', 'hardware'],
        src: bgNDA,
    },
];
